<template>
  <div
    @dragover.prevent
    @drop="dropItem"
    class="d-flex flex-column p-2"
  >
    <div class="d-flex align-items-center mb-3">
      <div class="flex-grow-1 smaller">Day {{ dayLabel + 1 }}</div>

      <Tooltip :content="'Create a new session'" :placement="'bottom'">
        <IconButton
          @click="addSession"
          v-if="authUser && program"
          :icon="'plus'"
          class="btn btn-circle"
        ></IconButton>
      </Tooltip>

      <BootstrapDropdown v-if="authUser && program">
        <li>
          <button
            @click="pasteSessions"
            :disabled="!sessionClipboardItems.length"
            role="button"
            class="dropdown-item small"
          >Paste session(s)</button>
        </li>
      </BootstrapDropdown>
    </div>

    <ProgramSessionItem
      v-for="session in sessions"
      :key="session.sessionId"
      :sessionId="session.sessionId"
      class="my-2"
    ></ProgramSessionItem>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProgramCalendarDay',
  components: {
    ProgramSessionItem: () => import('@/components/program/ProgramSessionItem'),
    Tooltip: () => import('@/components/Tooltip'),
    IconButton: () => import('@/components/button/IconButton'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    dayLabel: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('session', ['sessionClipboardItems']),
    ...mapState('program', ['program', 'programSessions', 'programSessionIdsInClipboard']),
    sessions() {
      const { programSessions, dayFormatted } = this;
      return programSessions.filter((s) => s.date === dayFormatted);
    },
    dayFormatted() {
      return this.day.format('YYYY-MM-DD');
    },
  },
  methods: {
    async addSession() {
      try {
        const { programId } = this.program;
        const { userId } = this.authUser;
        this.$store.dispatch('program/setBusy', true);
        await this.$store.dispatch('program/createSessionForProgram', {
          programId,
          authorId: userId,
          date: this.dayFormatted,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('program/setBusy', false);
      }
    },
    async pasteSessions() {
      this.$store.dispatch('program/setBusy', true);
      try {
        const { programId } = this.program;
        const { day, sessionClipboardItems } = this;
        await this.$store.dispatch('program/pasteSessions', {
          programId,
          day,
          sessions: sessionClipboardItems,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('program/setBusy', false);
      }
    },
    async dropItem(e) {
      const date = this.dayFormatted;
      const { dataTransfer } = e;
      const sessionId = dataTransfer.getData('sessionId');
      const sessionDate = dataTransfer.getData('sessionDate');
      if (sessionDate !== date) {
        try {
          this.$store.dispatch('program/setBusy', true);
          await this.$store.dispatch('program/moveSession', { sessionId: parseInt(sessionId, 10), date });
        } catch (err) {
          this.$store.dispatch('addSystemError', err, { root: true });
        } finally {
          this.$store.dispatch('program/setBusy', false);
        }
      }
    },
  },
};
</script>
